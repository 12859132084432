<template>
  <div
    v-if="modelImportModelObjectsStatus === 'success'"
    class="d-flex flex-column full-height"
  >
    <div
      class="d-flex align-center ant-glass-background ant-border-bottom radius-0 pa-2"
    >
      {{ modelImportSelectedModel.name }}
      <v-spacer />
      <div class="d-flex justify-center align-center mr-2">
        <v-btn
          color="primary"
          elevation="0"
          class="mr-2"
          small
          @click="fillWithTemplate"
          >Fill with template</v-btn
        >
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon class="mr-2" color="primary" v-bind="attrs" v-on="on">
              mdi-folder-outline
            </v-icon>
          </template>
          <span>Edit Property Directory</span>
        </v-tooltip>
        <v-text-field
          v-model="propertyDirectoryInput"
          :rules="[rules.required]"
          dense
          filled
          hide-details
          label="Property Directory"
          outlined
          style="max-width: 200px"
          @keydown.enter="updatePropertyDirectory"
        ></v-text-field>
      </div>
      <div class="d-flex justify-center align-center mr-2">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon class="mr-2" color="primary" v-bind="attrs" v-on="on">
              mdi-cube
            </v-icon>
          </template>
          <span>Estimated modules found in model</span>
        </v-tooltip>
        {{ distinctModules.length }}
      </div>
      <div class="d-flex justify-center align-center mr-2">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon class="mr-2" color="primary" v-bind="attrs" v-on="on">
              mdi-cube-outline
            </v-icon>
          </template>
          <span>Estimated elements found in model</span>
        </v-tooltip>
        {{ distinctElements.length }}
      </div>
      <div class="d-flex justify-center align-center mr-2">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon class="mr-2" color="primary" v-bind="attrs" v-on="on">
              mdi-cube-unfolded
            </v-icon>
          </template>
          <span>Estimated objects found in model</span>
        </v-tooltip>
        {{ distinctObjects.length }}
      </div>
    </div>
    <div
      v-if="modelImportSelectedModel.property_directory"
      class="d-flex flex-1 flex-scroll-height overflow-y-auto my-5"
    >
      <div class="d-flex flex-column flex-1 mx-2">
        <div
          :class="{
            'import-tile-imported': moduleMappingSaved,
          }"
          class="d-flex align-center justify-center ant-glass-background pa-5 import-tile mb-5"
        >
          <div class="d-flex flex-column">
            <v-icon class="import-tile-icon" large> mdi-cube</v-icon>
            <div>Import modules</div>
          </div>
        </div>
        <div class="ant-glass-background d-flex flex-column pa-2">
          <v-form ref="import-modules-form">
            <ant-input label="Build Nr">
              <template #input-field>
                <v-combobox
                  v-model="modelMapping.build_nr"
                  :disabled="moduleMappingSaved"
                  :items="mappingOptions"
                  :rules="[rules.required]"
                  clearable
                  dense
                  filled
                  hide-details
                  placeholder="Build Nr"
                />
              </template>
            </ant-input>
            <ant-input label="Module id">
              <template #input-field>
                <v-combobox
                  v-model="modelMapping.module_id"
                  :disabled="moduleMappingSaved"
                  :items="mappingOptions"
                  :rules="[rules.required]"
                  clearable
                  dense
                  filled
                  hide-details
                  placeholder="Module id"
                />
              </template>
            </ant-input>
            <ant-input is-optional label="Module type">
              <template #input-field>
                <v-combobox
                  v-model="modelMapping.module_type"
                  :disabled="moduleMappingSaved"
                  :items="mappingOptions"
                  clearable
                  dense
                  filled
                  hide-details
                  placeholder="Module type"
                />
              </template>
            </ant-input>
            <div class="d-flex align-center mt-2">
              <v-btn
                :disabled="moduleMappingSaved"
                class="flex-grow-1"
                color="primary"
                elevation="0"
                small
                @click="importModules"
              >
                {{ moduleMappingSaved ? 'Mapping Saved' : 'Save Mapping' }}
              </v-btn>
            </div>
          </v-form>
        </div>
      </div>
      <div class="d-flex flex-column flex-1 mx-2">
        <div
          :class="{
            'import-tile-imported': elementMappingSaved,
          }"
          class="d-flex align-center justify-center ant-glass-background pa-5 import-tile mb-5"
        >
          <div class="d-flex flex-column">
            <v-icon class="import-tile-icon" large> mdi-cube-outline</v-icon>
            <div>Import elements</div>
          </div>
        </div>
        <div class="ant-glass-background d-flex flex-column pa-2">
          <v-form ref="import-elements-form">
            <ant-input label="Composition id">
              <template #input-field>
                <v-combobox
                  v-model="modelMapping.composition_id"
                  :disabled="elementMappingSaved"
                  :items="mappingOptions"
                  :rules="[rules.required]"
                  clearable
                  dense
                  filled
                  hide-details
                  placeholder="Composition id"
                />
              </template>
            </ant-input>
            <ant-input label="Composition type">
              <template #input-field>
                <v-combobox
                  v-model="modelMapping.composition_type"
                  :disabled="elementMappingSaved"
                  :items="mappingOptions"
                  :rules="[rules.required]"
                  clearable
                  dense
                  filled
                  hide-details
                  placeholder="Composition type"
                />
              </template>
            </ant-input>
            <ant-input label="Element id">
              <template #input-field>
                <v-combobox
                  v-model="modelMapping.element_id"
                  :disabled="elementMappingSaved"
                  :items="mappingOptions"
                  :rules="[rules.required]"
                  clearable
                  dense
                  filled
                  hide-details
                  placeholder="Element id"
                />
              </template>
            </ant-input>
            <ant-input label="Element code">
              <template #input-field>
                <v-combobox
                  v-model="modelMapping.element_type"
                  :disabled="elementMappingSaved"
                  :items="mappingOptions"
                  :rules="[rules.required]"
                  clearable
                  dense
                  filled
                  hide-details
                  placeholder="Element code"
                />
              </template>
            </ant-input>
            <ant-input label="Element type">
              <template #input-field>
                <v-combobox
                  v-model="modelMapping.element_category"
                  :disabled="elementMappingSaved"
                  :items="mappingOptions"
                  :rules="[rules.required]"
                  clearable
                  dense
                  filled
                  hide-details
                  placeholder="Element type"
                />
              </template>
            </ant-input>
            <ant-input label="Element position">
              <template #input-field>
                <v-combobox
                  v-model="modelMapping.element_position"
                  :disabled="elementMappingSaved"
                  :items="mappingOptions"
                  :rules="[rules.required]"
                  clearable
                  dense
                  filled
                  hide-details
                  placeholder="Element position"
                />
              </template>
            </ant-input>
            <ant-input is-optional label="Element id lvl 2">
              <template #input-field>
                <v-combobox
                  v-model="modelMapping.element_id_level_2"
                  :disabled="elementMappingSaved"
                  :items="mappingOptions"
                  clearable
                  dense
                  filled
                  hide-details
                  placeholder="Element id"
                />
              </template>
            </ant-input>
            <ant-input is-optional label="Element id lvl 3">
              <template #input-field>
                <v-combobox
                  v-model="modelMapping.element_id_level_3"
                  :disabled="elementMappingSaved"
                  :items="mappingOptions"
                  clearable
                  dense
                  filled
                  hide-details
                  placeholder="Element id"
                />
              </template>
            </ant-input>
            <div class="d-flex align-center mt-2">
              <v-btn
                :disabled="elementMappingSaved"
                class="flex-grow-1"
                color="primary"
                elevation="0"
                small
                @click="importElements"
              >
                {{ elementMappingSaved ? 'Mapping Saved' : 'Save Mapping' }}
              </v-btn>
            </div>
          </v-form>
        </div>
      </div>
      <div class="d-flex flex-column flex-1 mx-2">
        <div
          :class="{
            'import-tile-imported': objectMappingSaved,
          }"
          class="d-flex align-center justify-center ant-glass-background pa-5 import-tile mb-5"
        >
          <div class="d-flex flex-column">
            <v-icon class="import-tile-icon" large> mdi-cube-unfolded</v-icon>
            <div>Import Objects</div>
          </div>
        </div>
        <div class="ant-glass-background d-flex flex-column pa-2">
          <v-form ref="import-objects-form">
            <ant-input label="Object id">
              <template #input-field>
                <v-combobox
                  v-model="modelMapping.object_id"
                  :disabled="objectMappingSaved"
                  :items="mappingOptions"
                  :rules="[rules.required]"
                  clearable
                  dense
                  filled
                  hide-details
                  placeholder="object id"
                />
              </template>
            </ant-input>
            <ant-input is-optional label="Object code">
              <template #input-field>
                <v-combobox
                  v-model="modelMapping.object_type"
                  :disabled="objectMappingSaved"
                  :items="mappingOptions"
                  clearable
                  dense
                  filled
                  hide-details
                  placeholder="object code"
                />
              </template>
            </ant-input>
            <ant-input is-optional label="Name">
              <template #input-field>
                <v-combobox
                  v-model="modelMapping.name"
                  :disabled="objectMappingSaved"
                  :items="mappingOptions"
                  clearable
                  dense
                  filled
                  hide-details
                  placeholder="name"
                />
              </template>
            </ant-input>

            <ant-input is-optional label="Height">
              <template #input-field>
                <v-combobox
                  v-model="modelMapping.height"
                  :disabled="objectMappingSaved"
                  :items="mappingOptions"
                  clearable
                  dense
                  filled
                  hide-details
                  placeholder="height"
                />
              </template>
            </ant-input>
            <ant-input is-optional label="Length">
              <template #input-field>
                <v-combobox
                  v-model="modelMapping.length"
                  :disabled="objectMappingSaved"
                  :items="mappingOptions"
                  clearable
                  dense
                  filled
                  hide-details
                  placeholder="length"
                />
              </template>
            </ant-input>
            <ant-input is-optional label="Width">
              <template #input-field>
                <v-combobox
                  v-model="modelMapping.width"
                  :disabled="objectMappingSaved"
                  :items="mappingOptions"
                  clearable
                  dense
                  filled
                  hide-details
                  placeholder="width"
                />
              </template>
            </ant-input>
            <ant-input is-optional label="Surface">
              <template #input-field>
                <v-combobox
                  v-model="modelMapping.surface"
                  :disabled="objectMappingSaved"
                  :items="mappingOptions"
                  clearable
                  dense
                  filled
                  hide-details
                  placeholder="surface"
                />
              </template>
            </ant-input>
            <ant-input is-optional label="NLSFB">
              <template #input-field>
                <v-combobox
                  v-model="modelMapping.NLSFB"
                  :disabled="objectMappingSaved"
                  :items="mappingOptions"
                  clearable
                  dense
                  filled
                  hide-details
                  placeholder="nlsfb"
                />
              </template>
            </ant-input>
            <div class="d-flex align-center mt-2">
              <v-btn
                :disabled="objectMappingSaved"
                class="flex-grow-1"
                color="primary"
                elevation="0"
                small
                @click="importObjects"
              >
                {{ objectMappingSaved ? 'Mapping Saved' : 'Save Mapping' }}
              </v-btn>
            </div>
          </v-form>
        </div>
      </div>
    </div>
    <div v-else class="d-flex justify-center align-center pa-5">
      <v-alert dense type="warning">
        Please fill in the Property Directory input to proceed.
      </v-alert>
    </div>
    <div
      v-if="objectMappingSaved && elementMappingSaved && moduleMappingSaved"
      class="pa-5 d-flex justify-center"
    >
      <v-btn
        :loading="isImporting"
        color="primary"
        elevation="0"
        @click="sendImportRequest"
        >Request import of 3D model
      </v-btn>
    </div>
  </div>
  <div v-else class="d-flex justify-center align-center full-height">
    <ant-loading />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AntLoading from '@/components/AntLoading.vue';
import { DHME_MODEL_IMPORT } from '@/modules/modules';
import AntInput from '@/components/AntInput.vue';
import { executeCustomModuleCall } from '@/services/api/module.api';
import { getTask } from '@/services/api/task.api';
import LocalStorageService from '@/services/local-storage';

export default {
  name: 'DhmeImportObjectElements',
  components: { AntInput, AntLoading },
  data: () => {
    return {
      modelMapping: {
        build_nr: undefined,
        module_id: undefined,
        module_type: undefined,

        composition_id: undefined,
        composition_type: undefined,
        element_id: undefined,
        element_type: undefined,
        element_category: undefined,
        element_id_level_2: undefined,
        element_id_level_3: undefined,

        object_id: undefined,
        object_type: undefined,
        name: undefined,
        height: undefined,
        width: undefined,
        length: undefined,
        surface: undefined,
        NLSFB: undefined,
      },

      moduleMappingSaved: false,
      elementMappingSaved: false,
      objectMappingSaved: false,
      isImporting: false,
      propertyDirectoryInput: '',
      rules: {
        required: (value) => !!value || 'Required.',
      },
    };
  },
  computed: {
    ...mapGetters([
      'project',
      'modelImportModelObjects',
      'modelImportModelObjectsStatus',
      'modelImportModuleModelMappingTable',
      'modelImportModuleModelMapping',
      'modelImportModuleClient',
      'modelImportAutodeskAccessToken',
      'modelImportModuleModelsTable',
      'accAccessToken',
      'modelImportSelectedModel',
      'modelImportModuleModulesTable',
      'modelImportModuleNonModulesTable',
      'modelImportModuleElementsTable',
      'modelImportModuleObjectsTable',
    ]),
    distinctModules() {
      return [
        ...new Set(
          this.modelImportModelObjects
            .filter(
              (object) =>
                object.properties[
                  this.modelImportSelectedModel.property_directory
                ]?.ModuleId
            )
            .filter(
              (object) =>
                !object.properties[
                  this.modelImportSelectedModel.property_directory
                ]?.ModuleId.startsWith('NonModuleObject;')
            )
            .map(
              (record) =>
                record.properties[
                  this.modelImportSelectedModel.property_directory
                ]?.ModuleId
            )
        ),
      ];
    },
    distinctElements() {
      return [
        ...new Set(
          this.modelImportModelObjects
            .filter(
              (object) =>
                object.properties[
                  this.modelImportSelectedModel.property_directory
                ]?.ElementId
            )
            .map(
              (record) =>
                record.properties[
                  this.modelImportSelectedModel.property_directory
                ]?.ElementId
            )
        ),
      ];
    },
    distinctObjects() {
      return [
        ...new Set(
          this.modelImportModelObjects
            .filter(
              (object) =>
                object.properties[
                  this.modelImportSelectedModel.property_directory
                ]?.ObjectId
            )
            .map(
              (record) =>
                record.properties[
                  this.modelImportSelectedModel.property_directory
                ]?.ObjectId
            )
        ),
      ];
    },
    mappingOptions() {
      let optionsSet = new Set();
      this.modelImportModelObjects.forEach((obj) => {
        Object.keys(obj.properties)
          .filter((x) =>
            x.startsWith(this.modelImportSelectedModel.property_directory)
          )
          .forEach((y) => {
            Object.keys(obj.properties[y]).forEach((key) => {
              let option = `${y}.${key}`;
              if (!optionsSet.has(option)) {
                optionsSet.add(option);
              }
            });
          });
      });
      return Array.from(optionsSet).sort((a, b) => a.localeCompare(b));
    },
    moduleId() {
      return this.project.modules.find(
        (module) => module.route === DHME_MODEL_IMPORT
      ).id;
    },
  },
  watch: {
    'modelImportSelectedModel.property_directory': {
      immediate: true,
      handler(value) {
        this.propertyDirectoryInput = value;
      },
    },
  },
  mounted() {
    this.$store.dispatch('fetchModuleImportModelModelProperties');
    if (this.modelImportModuleModelMapping) {
      this.modelMapping = { ...this.modelImportModuleModelMapping };
    }
  },
  methods: {
    async fillWithTemplate() {
      this.propertyDirectoryInput = 'DHME IDM';

      this.modelMapping = {
        build_nr: 'DHME IDM.BuildNumber',
        module_id: 'DHME IDM.ModuleId',
        module_type: 'DHME IDM.ModuleType',
        composition_id: 'DHME IDM.CompositionId',
        composition_type: 'DHME IDM (IFC Type).CompositionType',
        element_id: 'DHME IDM.ElementId',
        element_category: 'DHME IDM (IFC Type).ElementType',
        element_type: 'DHME IDM.ElementCode',
        element_id_level_2: 'DHME IDM.ElementIdLevel2',
        element_id_level_3: null,
        object_type: 'DHME IDM.ObjectCode',
        NLSFB: 'DHME IDM (IFC Type).ClassificationCode',
        height: 'DHME IDM.Height',
        length: 'DHME IDM.Length',
        width: 'DHME IDM.Width',
        surface: null,
        name: 'DHME IDM.Name',
        object_id: 'DHME IDM.ObjectId',
        element_position: 'DHME IDM.ElementPosition',
      };

      await this.updateModelMapping();
      this.updatePropertyDirectory();
    },
    updatePropertyDirectory() {
      // Example: API Call or Vuex Dispatch to Save Changes
      if (!this.propertyDirectoryInput) {
        this.$store.commit('showNotification', {
          color: 'error',
          content: 'Property Directory cannot be empty.',
        });
        return;
      }

      // If using Vuex or API to save the property
      this.$store
        .dispatch('updateModelPropertyDirectory', {
          recordId: this.modelImportSelectedModel.id,
          propertyDirectory: this.propertyDirectoryInput,
        })
        .then(() => {
          this.$store.commit('showNotification', {
            color: 'success',
            content: 'Property Directory updated successfully.',
          });
        })
        .catch(() => {
          this.$store.commit('showNotification', {
            color: 'error',
            content: 'Failed to update Property Directory.',
          });
        });
    },
    async importModules() {
      if (this.$refs['import-modules-form'].validate()) {
        await this.updateModelMapping();
        this.moduleMappingSaved = true;
      }
    },

    async importElements() {
      if (this.$refs['import-elements-form'].validate()) {
        await this.updateModelMapping();
        this.elementMappingSaved = true;
      }
    },

    async importObjects() {
      if (this.$refs['import-objects-form'].validate()) {
        await this.updateModelMapping();
        this.objectMappingSaved = true;
      }
    },
    async updateModelMapping() {
      await this.$store.dispatch('modelImportUpdateMapping', this.modelMapping);
    },

    async sendImportRequest() {
      this.isImporting = true;
      try {
        let task = await executeCustomModuleCall(
          this.project.id,
          this.moduleId,
          'sendImportRequest',
          {
            server_region: this.modelImportModuleClient.server_region,
            autodesk_token: this.modelImportSelectedModel.from_acc
              ? this.accAccessToken
              : this.modelImportAutodeskAccessToken,
            ANTToken: LocalStorageService.getToken(),
            project: this.project.id,
            objects_table: this.modelImportModuleObjectsTable,
            elements_table: this.modelImportModuleElementsTable,
            modules_table: this.modelImportModuleModulesTable,
            non_modules_table: this.modelImportModuleNonModulesTable,
            models_table: this.modelImportModuleModelsTable,
            mapping_table: this.modelImportModuleModelMappingTable,
            model: this.modelImportSelectedModel.id,
          }
        );

        this.$store.commit('showNotification', {
          content:
            "The import request has been send. You can monitor the progress in the 'Task' detail view. You will be redirected there in 5 seconds",
          color: 'info',
        });

        setTimeout(() => {
          this.$router.push({
            name: 'tasks-detail',
            params: { taskId: task.id },
          });
        }, 5000);
      } catch (e) {
        this.$store.commit('showNotification', {
          color: 'error',
          content: e.message,
        });
        this.isImporting = false; // If there is an error, stop loading indicator
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.import-tile {
  cursor: pointer;
  transition: 200ms;

  &.import-tile-imported {
    box-shadow: inset 0 0 0 2px var(--v-success-base) !important;

    .import-tile-icon {
      color: var(--v-success-base) !important;
    }
  }

  &.import-tile-processing {
    box-shadow: inset 0 0 0 2px var(--v-warning-base) !important;

    .import-tile-icon {
      color: var(--v-warning-base) !important;
    }
  }

  &:hover {
    background-color: white;
    box-shadow: inset 0 0 0 2px var(--v-primary-base);

    .import-tile-icon {
      color: var(--v-primary-base);
    }
  }
}
</style>
